<script setup>
import rhizome from './data/rhizome.json';
import '../assets/styles/main.css';

import router from '../router/index.js'

const routes = router.getRoutes()

</script>

<template>
<div>
    <h1 class="viewlist-ul">Contents</h1>
    <ul class="viewlist-ul">
        <li v-for="route of routes" :key="route.id" >
            <button :id="route.id" class="viewlist-btn"
                    @click="openModule($event)">
                <router-link class="viewlist-btn" :to=route.path>{{ route.name }}</router-link>
            </button>
        </li>
    </ul>
</div>
</template>

<style scoped>
.viewlist-btn {
  display: flex;
  flex-flow: column wrap;
  text-align: left;
  /* align-items: center; */
  /* align-content: center; */
  /* justify-content:center; */
  width:100%;
  height:12vw;
  max-height:50px;
  /* min-width: 12vw; */
  /* min-height: 12vw; */
  /* padding:1vw; */
  margin-left:auto;
  margin-right:auto;
  font-family: monospace;
  font-size: 1.5em;
  font-weight: bold;
  color: var(--color-complement-1a);
  background-color: var(--color-background-1);
  border:none;
  /* border-radius: 8vw; */
  /* list-style-type:none; */
  /* filter: drop-shadow( 0px 0px 3px var(--color-background-3)); */
}
.viewlist-btn-active {
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content:center;
  margin-left:auto;
  margin-right:auto;
  width:12vw;
  height:7vw;
  /* padding:1vw; */
  font-family: monospace;
  font-size: 3vw;
  font-weight: bold;
  color: var(--color-complement-1a);
  background-color: var(--color-background-1);
  border:none;
  /* border-radius: 6vw; */
  filter: drop-shadow( 0px 0px 3px var(--color-background-3));
}
.viewlist-btn:hover {
    filter: drop-shadow( 0px 0px 3px var(--color-background-3));
}
.viewlist-ul {
  list-style-type:none;
  /* float:left; */
  /* margin:0; */
  padding:.7vw;
  /* display:block; */
  /* position:relative; */
  /* align-items:start; */
  /* filter: none; */

}
</style>

<script>
export default {
    data() {
        return {
        rhizome: rhizome
        }
    },
    methods: {
        openModule(event) {
            // open target modules in 'rhizome-content' and close other modules
            let panel = document.getElementById('rhizome-content').childNodes;
            let lizt = document.getElementById('rhizome').childNodes[0].childNodes[0].childNodes;
            
            if (event.target.className == "viewlist-btn") {
                panel.forEach(
                    element => {
                        if (element.id == event.target.innerText) {
                            element.className = "rhizome-content";                        
                        } else {
                            element.className = "hide";
                        }                   
                    }
                )

                lizt.forEach(
                    element => {
                        if (element.innerText == event.target.innerText) {
                            let ecn = element.childNodes;
                            ecn.forEach(
                                element => {
                                    element.className = "rhizome-btn-active";
                                }
                            )
                        } else {
                            let cn = element.childNodes;
                            cn.forEach(
                                element => {
                                    element.className = "rhizome-btn";
                                }
                            )
                        }          
                    }
                )
            } else if (event.target.className == "rhizome-btn-active") {
                panel.forEach(
                    element => {
                        if (element.id == event.target.innerText) {
                            element.className = "hide";                        
                        } else {
                            element.className = "hide";
                        }                   
                    }
                )

                lizt.forEach(
                    element => {
                        if (element.innerText == event.target.innerText) {
                            let ecn = element.childNodes;
                            ecn.forEach(
                                element => {
                                    element.className = "rhizome-btn";
                                }
                            )
                        } else {
                            let cn = element.childNodes;
                            cn.forEach(
                                element => {
                                    element.className = "rhizome-btn";
                                }
                            )
                        }          
                    }
                )
            } else {
                console.log(event.target.className);
            }
        }
    }
};
</script>
