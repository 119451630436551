<template>
    <div class="modal-content">
      <div class="modal-header">
        <div><span class="modal-title">Share</span></div>
        <!-- <div id="filler"></div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="modal-body">
        <div class="modal-icons" v-for="tweet in tweets" :key="tweet.id">
            <button class="share-btn" :id=tweet.name>
                <a :class=tweet.class :href=tweet.url target="_blank"
                    :data-text=tweet.text :data-size=tweet.size
                    :data-hashtags=tweet.hashtags :data-url=tweet.srcUrl
                    >
                    <img svg-inline :src=tweet.logo class="share-img">
                </a>
            </button>
        </div>
        <!-- <Twitter :url="url"></Twitter> -->
        <!-- <div class="modal-icons" v-for="platform in platforms" :key="platform.id">
            <button :id=platform.name class="share-btn">
                <a :class=platform.class :href=platform.url target="_blank"
                    :data-text=platform.text :data-size=platform.size
                    :data-hashtags=platform.hashtags
                    >
                    <img svg-inline :src=platform.logo class="share-img">
                </a>
            </button>
        </div> -->
      </div>
      <div class="modal-footer">
        <!-- <div ><span>Share</span></div> -->
        <input id="urlInput" type="text" v-model="curPath">
        <button id="urlCopy" type="button" class="btn btn-primary" @click="copyText">Copy</button>
      </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
const vueUrl = 'https://rhizomatic.app'
const curRte = ref(router.currentRoute.value.fullPath)
let curPath = vueUrl + curRte.value
import FaceBook from '../assets/social-icons/f_logo_RGB-Blue_100.png'
import LinkedIn from '../assets/LinkedIn-Logos/LinkedIn-Logos/LI-In-Bug.png'
import Twitter from '../assets/twitter-logo-01282021/Twitter logo/SVG/Logo blue.svg'
import router from '../router';

const tweets = [
    { 
        "name": "Twitter Share Button",
        "url": "https://twitter.com/intent/tweet?text=hello&url=https%3A%2F%2Frhizomatic.app%2F&title=rhizomatic",
        "srcUrl": window.location.href,
        "logo": Twitter,
        "size": "large",
        // "class": "twitter-share-button",
        "text": "Look at this Dynamic Histogram I built with d3.js!",
        "hashtags": ["dataviz",]
    },
    {
        "name": "Facebook Share Button",
        "url": "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Frhizomatic.app%2F&amp;src=sdkpreparse",
        "class": "fb-xfbml-parse-ignore",
        "logo": FaceBook
    },
    {
        "name": "LinkedIn",
        "url": "https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Frhizomatic.app%2F&title=rhizomatic",
        "srcUrl": window.location.href,
        "logo": LinkedIn,

    },
]

function copyText() {
    let ctext = document.getElementById('urlInput')
    console.log(ctext.value)
    let cb = navigator.clipboard;
    cb.writeText(ctext.value);
}

// console.log(router.currentRoute.value.fullPath)
</script>
