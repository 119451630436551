<template>
<svg style="width:42vw;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 250">
  <g id="background">
    <rect class="cls-1" width="1000" height="250"/>
  </g>
  <g id="clue-type">
    <g>
      <path class="cls-3" d="M101.09,189.08c-13.3-.53-32.9-6.13-32.9-27.65,0-11.02,1.05-18.9,1.05-30.63,0-9.98-2.8-16.98-20.83-18.55v-6.83c18.03-1.23,20.83-7.7,20.83-19.08s-1.05-15.93-1.05-25.9c0-21.7,18.9-27.83,32.9-28.53v7.18c-13.3,2.45-15.23,9.8-15.23,18.38,0,10.15,1.23,16.8,1.23,25.73,0,14.7-11.2,22.93-23.1,25.2v.7c11.55,2.45,23.1,9.45,23.1,25.55,0,9.1-1.23,19.08-1.23,28.35s1.92,16.98,15.23,19.08v7Z"/>
      <path class="cls-3" d="M157.62,157.92h-46.2v-5.95c9.28-1.05,10.15-1.92,10.15-12.43v-38.33c0-8.75-.7-9.62-8.93-11.38v-5.43c10.85-1.22,20.65-3.32,31.5-6.65v18.9c7.88-12.08,14.18-18.55,21.35-18.55,6.48,0,11.38,4.38,11.38,10.5,0,8.58-6.12,13.48-8.93,14.88-2.45,1.05-4.2,.17-5.42-.88-2.98-2.45-5.25-5.25-8.23-5.25-2.62,0-7,2.28-10.15,8.93v33.25c0,10.5,1.23,11.03,13.48,12.43v5.95Z"/>
      <path class="cls-3" d="M228.84,157.92v-5.95c7.7-1.23,8.23-2.45,8.23-11.9v-30.8c0-13.48-5.6-18.03-12.43-18.03-4.2,0-8.75,1.23-12.78,5.08v43.75c0,9.45,.88,10.67,8.93,11.9v5.95h-41.65v-5.95c8.58-1.05,10.15-1.57,10.15-12.6V58.35c0-8.05-1.05-9.98-10.85-11.55v-5.6c12.77-1.05,25.03-3.5,33.43-5.95v54.25c6.12-5.95,14.7-11.38,23.63-11.38,15.23,0,24.33,9.98,24.33,29.23v32.03c0,11.03,1.23,11.2,9.63,12.6v5.95h-40.6Z"/>
      <path class="cls-3" d="M276.44,157.92v-5.95c9.45-1.23,10.5-1.92,10.5-14.35v-34.13c0-11.73-.88-12.43-9.1-13.48v-5.77c11.02-1.23,22.05-3.5,31.68-6.3v59.68c0,11.9,.7,13.12,10.85,14.35v5.95h-43.93Zm20.48-90.65c-6.65,0-12.6-5.6-12.6-12.43,0-7.18,5.95-12.43,12.77-12.43s12.6,5.25,12.6,12.43-5.6,12.43-12.78,12.43Z"/>
      <path class="cls-3" d="M399.81,133.07c-3.5,9.8-6.3,20.48-7.88,24.85h-62.83l-2.27-2.98c14.52-22.93,26.25-43.4,39.73-66.68h-9.1c-11.9,0-16.45,.7-23.8,16.98l-5.95-2.1c2.27-7.88,4.73-18.03,6.48-26.95h4.2c1.23,2.98,3.85,4.03,10.68,4.03h43.93l1.75,2.45c-13.48,23.1-25.55,43.93-38.85,67.38h11.2c12.25,0,18.2-2.28,27.13-19.08l5.6,2.1Z"/>
      <path class="cls-3" d="M447.24,78.12c24.68,0,40.25,18.55,40.25,39.55,0,28.18-20.3,42.35-40.95,42.35-26.43,0-41.48-19.95-41.48-40.43,0-26.78,22.05-41.48,42.18-41.48Zm-2.1,7c-7.53,0-14.88,7.88-14.88,29.4s7.18,38.33,18.2,38.33c6.65,0,13.83-4.55,13.83-30.1s-6.3-37.63-17.15-37.63Z"/>
      <path class="cls-3" d="M592.84,157.92v-5.95c7-1.05,8.23-1.75,8.23-12.43v-30.28c0-14.53-6.83-17.15-12.25-17.15-3.5,0-7.53,1.05-12.95,5.43,.17,2.1,.35,4.73,.35,7.35v35.35c0,9.8,.53,10.68,8.58,11.73v5.95h-39.38v-5.95c7.7-1.05,8.4-1.57,8.4-11.73v-31.85c0-12.43-4.9-16.45-11.38-16.45-4.38,0-8.58,1.58-13.48,5.25v43.05c0,9.8,1.05,10.68,7.52,11.73v5.95h-40.08v-5.95c9.63-1.23,10.15-1.92,10.15-12.43v-37.1c0-9.8-.18-11.38-8.4-12.6v-5.6c9.8-1.05,19.78-3.5,30.8-6.65v13.12c3.85-2.62,7-4.9,10.68-7.18,5.42-3.32,9.62-5.42,14.88-5.42,9.8,0,16.28,5.25,19.6,13.3,4.2-3.15,7.7-5.6,12.08-8.58,3.85-2.45,8.75-4.73,14-4.73,15.23,0,23.28,10.33,23.28,28.18v33.95c0,9.62,.88,10.33,9.45,11.73v5.95h-40.08Z"/>
      <path class="cls-3" d="M697.31,160.02c-4.73,0-8.58-1.58-10.85-3.68-2.45-2.1-3.68-4.55-4.55-6.48-6.65,4.38-15.23,10.15-18.55,10.15-15.05,0-23.8-11.73-23.8-22.58,0-9.27,4.2-13.48,13.48-17.15,10.33-3.85,24.15-8.05,28.18-11.55v-7.7c0-9.1-3.33-14.18-10.85-14.18-2.98,0-5.42,1.4-7,3.5-2.1,2.8-2.8,6.3-4.2,11.55-1.23,4.73-4.02,5.95-7.52,5.95-4.55,0-10.33-4.2-10.33-9.1,0-3.15,2.1-5.43,6.12-8.23,7.88-5.25,18.2-10.33,28-12.43,7,0,12.95,1.57,18.03,5.08,7.52,5.25,9.62,12.6,9.62,21.53v30.28c0,8.4,2.8,10.5,5.6,10.5,1.75,0,3.5-.52,5.43-1.4l1.92,5.95-18.73,9.98Zm-16.1-44.1c-3.15,1.75-6.3,3.5-9.8,5.42-5.43,2.8-8.58,5.95-8.58,11.9,0,9.8,6.83,13.3,10.68,13.3,2.45,0,4.9-.18,7.7-2.8v-27.83Z"/>
      <path class="cls-3" d="M755.41,158.1c-2.98,1.4-5.77,1.93-7,1.93-13.48,0-21.53-6.3-21.53-22.23v-48.83h-11.2l-1.05-2.1,5.07-6.65h7.18v-14.53c5.95-4.2,14.53-9.8,20.65-14l2.27,1.4c-.17,4.73-.52,14-.35,27.13h17.5c1.58,1.75,.88,7.17-1.4,8.75h-16.1v42.88c0,13.65,5.6,15.23,8.92,15.23,2.98,0,5.25-.52,8.4-1.75l1.75,6.3-13.13,6.48Z"/>
      <path class="cls-3" d="M773.26,157.92v-5.95c9.45-1.23,10.5-1.92,10.5-14.35v-34.13c0-11.73-.88-12.43-9.1-13.48v-5.77c11.02-1.23,22.05-3.5,31.68-6.3v59.68c0,11.9,.7,13.12,10.85,14.35v5.95h-43.93Zm20.48-90.65c-6.65,0-12.6-5.6-12.6-12.43,0-7.18,5.95-12.43,12.77-12.43s12.6,5.25,12.6,12.43-5.6,12.43-12.78,12.43Z"/>
      <path class="cls-3" d="M893.49,140.95c-8.05,11.55-19.78,19.08-30.45,19.08-24.15,0-38.33-18.2-38.33-37.63,0-12.95,6.65-25.2,17.85-33.43,11.55-8.58,22.58-10.85,30.45-10.85,6.65,0,12.25,1.92,15.4,4.2,2.45,1.75,3.33,3.85,3.33,6.48-.17,4.9-5.08,9.8-7.88,9.8-1.23,0-2.45-.52-3.85-1.92-7.53-6.3-13.48-9.1-18.2-9.1-6.83,0-15.05,6.65-15.05,25.55,0,23.28,16.98,30.8,24.5,30.8s12.42-1.92,18.73-7.35l3.5,4.38Z"/>
      <path class="cls-3" d="M900.66,31.92c13.3,.53,32.9,6.13,32.9,27.65,0,11.02-1.05,15.58-1.05,27.3,0,9.98,3.5,18.2,20.83,18.73v6.65c-16.1,1.23-20.83,7.7-20.83,19.08s1.05,18.2,1.05,28.18c0,21.53-18.9,28.88-32.9,29.58v-7.18c13.3-2.45,15.23-8.75,15.23-18.38,0-10.15-1.23-19.95-1.23-28.88,0-14.7,11.2-23.1,23.1-25.38v-.7c-11.55-2.27-23.1-10.85-23.1-25.55,0-9.1,1.23-15.75,1.23-25.03,0-10.85-1.92-16.98-15.23-19.08v-7Z"/>
    </g>
  </g>
  <g id="interactive-data">
    <path class="cls-2" d="M169.52,202.18c0-1.02,.1-2.04,.23-3.24l1.92-15.6h-5.8l.42-3.72h8.28l-2.35,19.8c-.1,.66-.13,1.32-.13,1.86,0,3.06,.98,4.32,2.41,4.32,.98,0,1.89-.6,3.03-1.5l.59,3.48c-1.34,1.14-2.61,1.92-4.34,1.92-2.54,0-4.27-2.52-4.27-7.32Zm2.25-31.98c0-2.22,1.04-4.14,2.25-4.14,1.14,0,1.89,1.2,1.89,3.24,0,2.34-1.04,4.14-2.28,4.14-1.04,0-1.86-1.14-1.86-3.24Z"/>
    <path class="cls-2" d="M186.34,179.62h2.09l-.42,5.94h.13c1.92-3.48,4.27-6.66,7.11-6.66s4.08,2.88,4.08,7.26c0,1.68-.13,3.18-.29,4.8l-1.96,17.82h-2.51l1.89-17.34c.16-1.5,.29-2.76,.29-4.08,0-2.88-.88-4.44-2.51-4.44-2.12,0-4.3,2.58-6.46,7.02l-2.05,18.84h-2.51l3.13-29.16Z"/>
    <path class="cls-2" d="M206.79,201.16c0-1.26,.1-2.4,.26-3.78l1.57-14.04h-4.6l.36-3.42,4.76-.3,1.37-9.36h2.09l-1.04,9.36h7.79l-.42,3.72h-7.83l-1.6,14.16c-.13,1.08-.16,1.86-.16,2.64,0,3.72,1.14,5.46,2.93,5.46,1.57,0,2.97-.9,4.11-2.1l.81,3.36c-1.37,1.56-3.36,2.64-5.58,2.64-3.33,0-4.83-3.36-4.83-8.34Z"/>
    <path class="cls-2" d="M223.23,196.9c0-10.5,4.24-18,9.03-18,3.88,0,6.03,4.74,6.03,11.22,0,2.04-.26,4.44-.42,5.22h-12.72l.33-3.6h11.31l-1.04,.9c.13-.84,.2-1.8,.2-2.7,0-4.56-1.47-7.38-3.82-7.38-3.06,0-6.42,5.64-6.42,14.1,0,5.88,1.89,9.18,4.76,9.18,2.09,0,3.95-1.62,5.41-3.78l.95,3.12c-1.6,2.34-4.08,4.32-6.85,4.32-4.11,0-6.75-4.44-6.75-12.6Z"/>
    <path class="cls-2" d="M247.03,179.56l2.09,.06-.69,8.34h.13c1.79-4.98,4.34-9.06,7.11-9.06,1.37,0,2.45,.54,3.2,1.5l-1.01,3.96c-.81-.9-1.6-1.26-2.64-1.26-2.45,0-4.99,4.26-7.14,10.56l-1.66,15.12h-2.51l3.13-29.22Z"/>
    <path class="cls-2" d="M262,198.34c0-10.44,3.88-19.44,8.71-19.44,2.25,0,3.95,1.74,4.83,4.38h.13l.65-3.66h2.05l-3.2,29.16h-2.05l.23-4.44h-.13c-1.66,3.12-3.75,5.16-5.8,5.16-3.26,0-5.41-3.9-5.41-11.16Zm11.61,1.62l1.47-12.9c-1.08-3.06-2.58-4.26-4.04-4.26-3.62,0-6.46,7.02-6.46,14.82,0,5.46,1.44,7.98,3.62,7.98,1.73,0,3.68-1.92,5.41-5.64Z"/>
    <path class="cls-2" d="M282.06,196.66c0-10.62,4.6-17.76,9.55-17.76,2.77,0,4.83,2.46,5.97,5.22l-1.63,2.64c-1.14-2.64-2.61-3.96-4.66-3.96-3.26,0-6.68,5.88-6.68,13.32,0,6.36,2.15,9.48,5.09,9.48,1.86,0,3.59-1.62,5.05-4.02l1.01,3.18c-1.66,2.82-4.01,4.74-6.39,4.74-4.14,0-7.3-4.32-7.3-12.84Z"/>
    <path class="cls-2" d="M304.63,201.16c0-1.26,.1-2.4,.26-3.78l1.57-14.04h-4.6l.36-3.42,4.76-.3,1.37-9.36h2.09l-1.04,9.36h7.79l-.42,3.72h-7.83l-1.6,14.16c-.13,1.08-.16,1.86-.16,2.64,0,3.72,1.14,5.46,2.93,5.46,1.57,0,2.97-.9,4.11-2.1l.81,3.36c-1.37,1.56-3.36,2.64-5.58,2.64-3.33,0-4.83-3.36-4.83-8.34Z"/>
    <path class="cls-2" d="M326.05,202.18c0-1.02,.1-2.04,.23-3.24l1.92-15.6h-5.8l.42-3.72h8.28l-2.35,19.8c-.1,.66-.13,1.32-.13,1.86,0,3.06,.98,4.32,2.41,4.32,.98,0,1.89-.6,3.03-1.5l.59,3.48c-1.34,1.14-2.61,1.92-4.34,1.92-2.54,0-4.27-2.52-4.27-7.32Zm2.25-31.98c0-2.22,1.04-4.14,2.25-4.14,1.14,0,1.89,1.2,1.89,3.24,0,2.34-1.04,4.14-2.28,4.14-1.04,0-1.86-1.14-1.86-3.24Z"/>
    <path class="cls-2" d="M341.41,179.62h2.48l1.99,16.92c.33,2.88,.62,5.82,.91,8.58h.13c.75-2.7,1.66-5.64,2.64-8.64l5.41-16.86h2.58l-9.59,29.16h-2.97l-3.59-29.16Z"/>
    <path class="cls-2" d="M360.2,196.9c0-10.5,4.24-18,9.03-18,3.88,0,6.03,4.74,6.03,11.22,0,2.04-.26,4.44-.42,5.22h-12.72l.33-3.6h11.31l-1.04,.9c.13-.84,.2-1.8,.2-2.7,0-4.56-1.47-7.38-3.82-7.38-3.06,0-6.42,5.64-6.42,14.1,0,5.88,1.89,9.18,4.76,9.18,2.09,0,3.95-1.62,5.41-3.78l.95,3.12c-1.6,2.34-4.08,4.32-6.85,4.32-4.11,0-6.75-4.44-6.75-12.6Z"/>
    <path class="cls-2" d="M400.23,195.19v-2.7l9.1-11.38v3.42l-7.46,9.22v.18l7.46,9.22v3.42l-9.1-11.38Z"/>
    <path class="cls-2" d="M424.5,176.83h1.79l-12.28,39.15h-1.76l12.25-39.15Z"/>
    <path class="cls-2" d="M429.24,203.16l7.46-9.22v-.18l-7.46-9.22v-3.42l9.1,11.38v2.7l-9.1,11.38v-3.42Z"/>
    <path class="cls-2" d="M462.56,198.34c0-10.44,3.88-19.44,8.71-19.44,2.25,0,3.85,1.68,4.76,4.26h.1l.55-5.7,1.24-11.4h2.51l-4.7,42.72h-2.05l.23-4.44h-.13c-1.66,3.12-3.75,5.16-5.8,5.16-3.26,0-5.41-3.9-5.41-11.16Zm11.61,1.62l1.47-12.9c-1.08-3.06-2.58-4.26-4.04-4.26-3.62,0-6.46,7.02-6.46,14.82,0,5.46,1.44,7.98,3.62,7.98,1.73,0,3.68-1.92,5.41-5.64Z"/>
    <path class="cls-2" d="M482.13,198.34c0-10.44,3.88-19.44,8.71-19.44,2.25,0,3.95,1.74,4.83,4.38h.13l.65-3.66h2.05l-3.2,29.16h-2.05l.23-4.44h-.13c-1.66,3.12-3.75,5.16-5.8,5.16-3.26,0-5.41-3.9-5.41-11.16Zm11.61,1.62l1.47-12.9c-1.08-3.06-2.58-4.26-4.04-4.26-3.62,0-6.46,7.02-6.46,14.82,0,5.46,1.44,7.98,3.62,7.98,1.73,0,3.68-1.92,5.41-5.64Z"/>
    <path class="cls-2" d="M505.19,201.16c0-1.26,.1-2.4,.26-3.78l1.57-14.04h-4.6l.36-3.42,4.76-.3,1.37-9.36h2.09l-1.04,9.36h7.79l-.42,3.72h-7.83l-1.6,14.16c-.13,1.08-.16,1.86-.16,2.64,0,3.72,1.14,5.46,2.93,5.46,1.57,0,2.97-.9,4.11-2.1l.81,3.36c-1.37,1.56-3.36,2.64-5.58,2.64-3.33,0-4.83-3.36-4.83-8.34Z"/>
    <path class="cls-2" d="M521.26,198.34c0-10.44,3.88-19.44,8.71-19.44,2.25,0,3.95,1.74,4.83,4.38h.13l.65-3.66h2.05l-3.2,29.16h-2.05l.23-4.44h-.13c-1.66,3.12-3.75,5.16-5.8,5.16-3.26,0-5.41-3.9-5.41-11.16Zm11.61,1.62l1.47-12.9c-1.08-3.06-2.58-4.26-4.04-4.26-3.62,0-6.46,7.02-6.46,14.82,0,5.46,1.44,7.98,3.62,7.98,1.73,0,3.68-1.92,5.41-5.64Z"/>
    <path class="cls-2" d="M561.66,195.19v-2.7l9.1-11.38v3.42l-7.46,9.22v.18l7.46,9.22v3.42l-9.1-11.38Z"/>
    <path class="cls-2" d="M585.92,176.83h1.79l-12.28,39.15h-1.76l12.25-39.15Z"/>
    <path class="cls-2" d="M590.67,203.16l7.46-9.22v-.18l-7.46-9.22v-3.42l9.1,11.38v2.7l-9.1,11.38v-3.42Z"/>
    <path class="cls-2" d="M623.99,198.34c0-10.44,3.88-19.44,8.71-19.44,2.25,0,3.85,1.68,4.76,4.26h.1l.55-5.7,1.24-11.4h2.51l-4.7,42.72h-2.05l.23-4.44h-.13c-1.66,3.12-3.75,5.16-5.8,5.16-3.26,0-5.41-3.9-5.41-11.16Zm11.61,1.62l1.47-12.9c-1.08-3.06-2.58-4.26-4.04-4.26-3.62,0-6.46,7.02-6.46,14.82,0,5.46,1.44,7.98,3.62,7.98,1.73,0,3.68-1.92,5.41-5.64Z"/>
    <path class="cls-2" d="M643.91,196.9c0-10.5,4.24-18,9.03-18,3.88,0,6.03,4.74,6.03,11.22,0,2.04-.26,4.44-.42,5.22h-12.72l.33-3.6h11.31l-1.04,.9c.13-.84,.2-1.8,.2-2.7,0-4.56-1.47-7.38-3.82-7.38-3.06,0-6.42,5.64-6.42,14.1,0,5.88,1.89,9.18,4.76,9.18,2.09,0,3.95-1.62,5.41-3.78l.95,3.12c-1.6,2.34-4.08,4.32-6.85,4.32-4.11,0-6.75-4.44-6.75-12.6Z"/>
    <path class="cls-2" d="M664.26,179.62h2.48l1.99,16.92c.33,2.88,.62,5.82,.91,8.58h.13c.75-2.7,1.66-5.64,2.64-8.64l5.41-16.86h2.58l-9.59,29.16h-2.97l-3.59-29.16Z"/>
    <path class="cls-2" d="M683.05,196.9c0-10.5,4.24-18,9.03-18,3.88,0,6.03,4.74,6.03,11.22,0,2.04-.26,4.44-.42,5.22h-12.72l.33-3.6h11.31l-1.04,.9c.13-.84,.2-1.8,.2-2.7,0-4.56-1.47-7.38-3.82-7.38-3.06,0-6.42,5.64-6.42,14.1,0,5.88,1.89,9.18,4.76,9.18,2.09,0,3.95-1.62,5.41-3.78l.95,3.12c-1.6,2.34-4.08,4.32-6.85,4.32-4.11,0-6.75-4.44-6.75-12.6Z"/>
    <path class="cls-2" d="M706.92,201.7c0-.96,.07-1.98,.23-3.18l3.26-28.74h-5.25l.42-3.72h7.73l-3.68,32.64c-.1,.84-.16,1.74-.16,2.64,0,3,.95,4.26,2.38,4.26,1.04,0,1.96-.48,3.1-1.56l.62,3.54c-1.4,1.26-2.84,1.92-4.4,1.92-2.45,0-4.24-2.64-4.24-7.8Z"/>
    <path class="cls-2" d="M721.63,196.72c0-10.2,4.27-17.82,9.13-17.82,4.14,0,6.62,5.22,6.62,12.78,0,10.2-4.27,17.82-9.1,17.82-4.17,0-6.65-5.22-6.65-12.78Zm13.21-4.74c0-5.64-1.6-9.18-4.27-9.18-3.36,0-6.39,6.06-6.39,13.62,0,5.64,1.6,9.18,4.27,9.18,3.36,0,6.39-6.06,6.39-13.62Z"/>
    <path class="cls-2" d="M744.03,179.62h2.09l-.29,4.5h.13c1.63-2.82,3.65-5.22,5.84-5.22,3.29,0,5.41,4.14,5.41,11.58,0,10.56-3.49,19.02-8.71,19.02-1.99,0-3.75-1.74-4.76-4.2h-.13l-.59,6.24-.98,9.54h-2.51l4.5-41.46Zm10.6,11.52c0-5.64-1.47-8.34-3.68-8.34-1.6,0-3.65,2.1-5.32,5.58l-1.47,13.08c1.11,3.06,2.64,4.14,4.17,4.14,3.62,0,6.29-6.6,6.29-14.46Z"/>
    <path class="cls-2" d="M762.42,179.62h1.92l-.23,3.96h.1c1.27-2.94,2.64-4.68,4.27-4.68,1.47,0,2.32,1.44,2.38,4.98l-.39,.66c1.63-4.08,3.26-5.64,4.83-5.64s2.48,1.62,2.48,5.1c0,1.02-.03,1.86-.2,3.3l-2.35,21.48h-2.48l2.28-20.88c.1-.96,.13-1.62,.13-2.28,0-1.98-.39-2.76-1.11-2.76-1.14,0-2.12,1.62-3.29,4.8l-2.32,21.12h-2.41l2.35-20.88c.1-.96,.13-1.62,.13-2.28,0-1.98-.46-2.76-1.14-2.76-1.21,0-2.15,1.62-3.39,4.8l-2.25,21.12h-2.48l3.16-29.16Z"/>
    <path class="cls-2" d="M780.88,196.9c0-10.5,4.24-18,9.03-18,3.88,0,6.03,4.74,6.03,11.22,0,2.04-.26,4.44-.42,5.22h-12.72l.33-3.6h11.31l-1.04,.9c.13-.84,.2-1.8,.2-2.7,0-4.56-1.47-7.38-3.82-7.38-3.06,0-6.42,5.64-6.42,14.1,0,5.88,1.89,9.18,4.76,9.18,2.09,0,3.95-1.62,5.41-3.78l.95,3.12c-1.6,2.34-4.08,4.32-6.85,4.32-4.11,0-6.75-4.44-6.75-12.6Z"/>
    <path class="cls-2" d="M802.7,179.62h2.09l-.42,5.94h.13c1.92-3.48,4.27-6.66,7.11-6.66s4.08,2.88,4.08,7.26c0,1.68-.13,3.18-.29,4.8l-1.96,17.82h-2.51l1.89-17.34c.16-1.5,.29-2.76,.29-4.08,0-2.88-.88-4.44-2.51-4.44-2.12,0-4.3,2.58-6.46,7.02l-2.05,18.84h-2.51l3.13-29.16Z"/>
    <path class="cls-2" d="M823.15,201.16c0-1.26,.1-2.4,.26-3.78l1.57-14.04h-4.6l.36-3.42,4.76-.3,1.37-9.36h2.09l-1.04,9.36h7.79l-.42,3.72h-7.83l-1.6,14.16c-.13,1.08-.16,1.86-.16,2.64,0,3.72,1.14,5.46,2.93,5.46,1.57,0,2.97-.9,4.11-2.1l.81,3.36c-1.37,1.56-3.36,2.64-5.58,2.64-3.33,0-4.83-3.36-4.83-8.34Z"/>
  </g>
  <g id="interactive-data-2" data-name="interactive-data">
    <path class="cls-2" d="M891.24,65.31c-.17-1.19-.44-2.36-1.36-2.36-.76,0-1.03,1.03-1.03,1.96,0,1.24,.43,1.87,1.24,2.65,1.17,1.12,1.68,1.94,1.68,3.65,0,1.89-.89,3.33-2.15,3.33-.59,0-1.1-.31-1.5-.59-.08-.5-.2-1.73-.26-2.7l.3-.14c.2,1.21,.63,2.77,1.64,2.77,.7,0,1.13-.9,1.13-2.07,0-1.39-.4-1.94-1.26-2.81-1.03-1.03-1.6-1.89-1.6-3.57s.72-3.11,2.02-3.11c.55,0,1.09,.27,1.29,.34,.04,.65,.08,1.37,.16,2.54l-.3,.11Z"/>
    <path class="cls-2" d="M898.2,74.27v-.5c.8-.14,.83-.25,.82-2.18v-7.35c-.79,3.29-2.08,8.44-2.43,10.03h-.25c-.33-1.64-1.44-6.59-2.18-9.85-.17,6.3-.18,7.72-.18,8.07,0,1.01,.25,1.15,.92,1.28v.5h-2.32v-.5c.57-.11,.8-.32,.88-1.3,.06-.79,.07-1.44,.32-8.01,.04-.94-.06-1.24-.92-1.39v-.5h1.7l2.16,9.13,2.32-9.13h1.71v.5c-.84,.18-.95,.34-.93,1.6l.04,6.91c.04,1.93,.07,2.02,.93,2.18v.5h-2.6Z"/>
  </g>
</svg>
</template>
<style type="text/css">
@import '@/assets/styles/main.css';
@import '@/assets/styles/svg-styles.css';
</style>