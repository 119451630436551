 <template>
    <p>Time to Arrival:<br>{{ remaining.days + " days " }}
    {{ remaining.hours + " hours "}}
    {{ remaining.minutes + " mins "}}
    {{ remaining.seconds + " seconds"}}
    </p>    
</template>

<script>
import Countdown from "./modules/countdown.mjs";
export default {
  data () {
    return {
      goal: new Date("July 4, 2022 00:00:00").getTime(),
      remaining: {days: null,hours: null,minutes: null,seconds: null} 
    }
  },
  created () {
    const update = () => {
      this.remaining = Countdown.Countdown(this.goal)
      this.handle = requestAnimationFrame(update)
    }
    update()
  },
  unmounted() {
    cancelAnimationFrame(this.handle)
  }
}
</script>
