<template>
  <div v-for="platform in platforms" :key="platform.id">
      <button :id=platform.name class="social-btn">
          <a :href=platform.url target="_blank">
              <img svg-inline :src=platform.logo class="social-img">
          </a>
      </button>
  </div>
</template>

<style scoped>
.social-ul {
  list-style-type:none;/*DO NOT CHANGE*/
  /* top:12vw; */
  display:inline-flex;/*DO NOT CHANGE*/
  /* justify-content:space-around; */
  /* float:right; */
  z-index: 2;/*DO NOT CHANGE*/
  height: 12vw;
  background-color: var(--color-background-1);
}

.social-btn {
  width:12vw;
  max-width: 160px;
  height:12vw;
  /* max-height: 150px; */
  /* padding:3vw; */
  /* margin:.5vw; */
  font-family: monospace;
  /* font-size: 3vw; */
  /* font-weight: bold; */
  color: var(--color-complement-1a);
  background-color: var(--color-background-1);
  border: none;
  border-radius: 6vw;
  line-height: 0;
  /* filter: drop-shadow( 0px 0px 3px var(--color-background-3)); */
}

.social-img:hover {
  filter: drop-shadow( 0px 0px 6px var(--color-background-3));

}

.social-img {
  width: 6vw;
  /* max-width: 120px; */
}
</style>

<script>
import LinkedIn from '../assets/social-icons/linkedin.svg'
import YouTube from '../assets/social-icons/youtube.svg'
import GitHub from '../assets/social-icons/github.svg'
// import Twitter from '../assets/social-icons/twitter.svg'
import Medium from '../assets/social-icons/medium.svg' 
const platforms = [
    // { 
    //     "name": "Twitter",
    //     "url": "https://www.twitter.com/buck_wilders",
    //     "logo": Twitter
    // },
    {
        "name": "LinkedIn",
        "url": "https://www.linkedin.com/in/adam-childers-7382702b/",
        "logo": LinkedIn
    },
    {
        "name": "Github",
        "url": "https://www.github.com/semiographics",
        "logo": GitHub
    },
    {
        "name": "YouTube",
        "url": "https://www.youtube.com/channel/UCvUHTQcjHMuO_RQ3zmOHg3Q",
        "logo": YouTube
    },
    {
        "name": "Medium",
        "url": "https://medium.com/@adam.childers",
        "logo": Medium
    }
]

export default {
  data() {
    return {
      platforms: platforms,
    }
  }
}
</script>
