<template>
<div class="body">
    <div id="header">
        <nav id="topNav">
            <div id="explore">
                <div>
                    <button id="explore-btn" @click="explore" class="fallingwater-btn">
                        <i class="bi bi-list" style="font-size: 9vw;line-height:0;"></i>
                    </button>
                </div>
            </div>
            <div id="logo" v-show="logoOn">
                <LogoMain @click="home" />
            </div>
            <div id="social" v-show="socialOn">
                <SocialList />
            </div>
            <div id="connect">
                <div>
                    <button id="connect-btn" @click="connect" class="fallingwater-btn">
                        <i class="bi bi-grid-3x3-gap" style="font-size: 9vw;line-height:0;"></i>
                    </button>
                </div>
            </div>
        </nav>
        <div id="organization" v-show="rhizomeOn">
            <div id="rhizome">
                <RhizomeMenu />
            </div>
            <div id="rhizome-content">
                <div id="about" class="hide">
                    about<TimeToArrival />
                </div>
                <div id="service" class="hide">
                    service<TimeToArrival />
                </div>
                <div id="method" class="hide">
                    method<TimeToArrival />
                </div>
                <div id="process" class="hide">
                    process<TimeToArrival />
                </div>
                <div id="results" class="hide">
                    results<TimeToArrival />
                </div>
            </div>
        </div>
    </div>
    <div id="main" v-show="mainOn" @click="home">
        <RouterView />
    </div>
    <div id="footer">
        <div id="viewShare">
            <div id="views" v-show="viewsOn" >
                <ViewList />
            </div>
            <div id="modal" v-show="modalOn">
                <ShareModal />
            </div>
        </div>
        <nav id="bottomNav">
            <div id="expand">
                <div>
                    <button id="expand-btn" @click="expand" class="fallingwater-btn">
                        <i class="bi bi-card-list" style="font-size: 6vw;line-height:0;"></i>
                    </button>
                </div>
            </div>
            <div id="browse" v-show="browseOn">
                <div>
                    <!-- <i class="bi bi-caret-left"></i> -->
                </div>
                <div>{{ router.currentRoute.value.name }}</div>
                <div>
                    <!-- <i class="bi bi-caret-right"></i> -->
                </div>
            </div>
            <div id="share">
                <div>
                    <button id="share-btn" @click="share" class="fallingwater-btn">
                        <i class="bi bi-share" style="font-size: 6vw;line-height:0;"></i>
                    </button>
                </div>
            </div>
        </nav>
    </div>
</div>
</template>

<!-- <i class="bi bi-caret-left"></i> -->
<!-- <i class="bi bi-caret-left-fill"></i> -->
<!-- <i class="bi bi-caret-left-square"></i> -->
<!-- <i class="bi bi-caret-left-square-fill"></i> -->
<!-- <i class="bi bi-activity"></i> -->
<!-- <i class="bi bi-at"></i><i class="bi bi-hash"></i> -->
<!-- <i class="bi bi-share-fill"></i> -->
<!-- <i class="bi bi-caret-right"></i> -->
<!-- <i class="bi bi-caret-right-fill"></i> -->
<!-- <i class="bi bi-caret-right-square"></i> -->
<!-- <i class="bi bi-caret-right-square-fill"></i> -->

<style>
@import './assets/styles/main.css';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");
</style>

<script setup>
import { ref } from 'vue'
import { RouterView } from 'vue-router'
import LogoMain from './components/LogoMain.vue';
import TimeToArrival from './components/TimeToArrival.vue';
import RhizomeMenu from './components/RhizomeMenu.vue';
import SocialList from './components/SocialList.vue';
import ShareModal from './components/ShareModal.vue'
import router from './router';
import ViewList from './components/ViewList.vue';


const logoOn = ref(true);
const mainOn = ref(true);

const socialOn = ref(false);
const rhizomeOn = ref(false);

const viewsOn = ref(false);
const browseOn = ref(true);
const modalOn = ref(false);

const routes = router.getRoutes()
routes.forEach((route, i) => route.id = i + 1);
// routes.forEach(route => console.log(route.id))

// const curRoute = router.currentRoute.value.name
// routes.forEach(route => {
//     console.log(route.id)
// })

// const curId = router.currentRoute.value
// console.log(curId)

// routes.forEach(route => {
//     if (route.name == curRoute) {
//         console.log(route.id)
//     } else {
//         console.log(route.name)
//     }
// })

function explore() {
    if (rhizomeOn.value == false) {
        rhizomeOn.value = true

        modalOn.value = false
        viewsOn.value = false
        document.getElementById('explore-btn').className = "fallingwater-btn-active"
        document.getElementById('connect-btn').className = "fallingwater-btn"
        document.getElementById('share-btn').className = 'fallingwater-btn'
        document.getElementById('expand-btn').className = 'fallingwater-btn'
        } else if (rhizomeOn.value == true) {
            rhizomeOn.value = false
            document.getElementById('explore-btn').className = 'fallingwater-btn'
            }
}

function connect() {
  if (socialOn.value == false) {
      socialOn.value = true
      logoOn.value = false
    //   rhizomeOn.value = false
      modalOn.value = false
    //   viewsOn.value = false
      mainOn.value = true
    //   document.getElementById('explore-btn').className = "fallingwater-btn"
      document.getElementById('connect-btn').className = "fallingwater-btn"
      document.getElementById('share-btn').className = 'fallingwater-btn'
      document.getElementById('expand-btn').className = 'fallingwater-btn'
      } else if (socialOn.value == true) {
          socialOn.value = false
          logoOn.value = true
          document.getElementById('connect-btn').className = "fallingwater-btn"
          }
    }

function share() {
    if (modalOn.value == false) {
        modalOn.value = true
        viewsOn.value = false
        logoOn.value = true
        socialOn.value = false
        rhizomeOn.value = false
        document.getElementById('explore-btn').className = "fallingwater-btn"
        document.getElementById('connect-btn').className = "fallingwater-btn"
        document.getElementById('share-btn').className = 'fallingwater-btn-active'
        document.getElementById('expand-btn').className = 'fallingwater-btn'
        } else if (modalOn.value == true) {
            modalOn.value = false
            document.getElementById('share-btn').className = 'fallingwater-btn'
            }
    }

function expand() {
    if (viewsOn.value == false) {
        viewsOn.value = true

        modalOn.value = false
        // logoOn.value = true
        // socialOn.value = false
        rhizomeOn.value = false
        document.getElementById('explore-btn').className = "fallingwater-btn"
        document.getElementById('connect-btn').className = "fallingwater-btn"
        document.getElementById('share-btn').className = 'fallingwater-btn'
        document.getElementById('expand-btn').className = 'fallingwater-btn-active'
    } else if (viewsOn.value == true) {
        viewsOn.value = false
        document.getElementById('expand-btn').className = 'fallingwater-btn'
    }
}

function home() {
    socialOn.value = false
    rhizomeOn.value = false
    mainOn.value = true
    logoOn.value = true
    viewsOn.value = false
    modalOn.value = false
    document.getElementById('explore-btn').className = "fallingwater-btn"
    document.getElementById('connect-btn').className = "fallingwater-btn"
    document.getElementById('share-btn').className = 'fallingwater-btn'
    document.getElementById('expand-btn').className = 'fallingwater-btn'
}

</script>




