function Countdown(targetDate) {
  let clock = targetDate - new Date().getTime();
  if (clock > 0) {
      let days = clock/86400000;
      let hours = 24*(days - Math.floor(days));
      let minutes = 60*(hours - Math.floor(hours));
      let seconds = 60*(minutes - Math.floor(minutes));
      let timeRemaining = {}
      timeRemaining.days = Math.floor(days)
      timeRemaining.hours = Math.floor(hours)
      timeRemaining.minutes = Math.floor(minutes)
      timeRemaining.seconds = seconds.toFixed(1)
      return timeRemaining
      }
  else return "EXPIRED";
}

export default { Countdown };
